import { Subtitle1 } from '@fluentui/react-components';
import React from 'react';
import { AuthHelper } from '../..//libs/auth/AuthHelper';
import { AppState, useClasses } from '../../App';
import { UserSettingsMenu } from '../header/UserSettingsMenu';
import { PluginGallery } from '../open-api-plugins/PluginGallery';
import { BackendProbe, ChatView, Error, Loading } from '../views';

const Chat = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                  AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                  AppState.LoadingChats,
        );
    }, [setAppState]);
    return (
        <div className={classes.container}>
            <div className={classes.header} style={{ display: 'none' }}>
                <Subtitle1 as="h1">Themis</Subtitle1>
                {appState > AppState.SettingUserInfo && (
                    <div className={classes.cornerItems}>
                        <div className={classes.cornerItems}>
                            <PluginGallery />
                            <UserSettingsMenu
                                setLoadingState={() => {
                                    setAppState(AppState.SigningOut);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Por favor aguarde enquanto buscamos as informa��es...'} />
            )}
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'N�o foi poss�vel carregar as informa��es do utilizador. Por favor, tente sair e entrar novamente.'} />
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'N�o foi poss�vel carregar as conversas. Por favor, tente atualizar a p�gina.'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="A carregar conversas..." />}
            {appState === AppState.Chat && <ChatView />}
        </div>
    );
};
export default Chat;
